<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <h4 class="mt-5">Qibla</h4>
            <div class="compass">
              <div class="arrow"></div>
              <div class="disc" id="compassDiscImg"></div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="orientation-data">
      <div class="text-white">
        Beta: <span class="text-white" id="tiltFB"></span>
      </div>
      <div class="text-white">
        Gamma: <span class="text-white" id="tiltLR"></span>
      </div>
      <div class="text-white">
        Alpha: <span class="text-white" id="direction"></span>
      </div>
    </div>
    <div id="notice"></div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Wallpoet");

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.compass {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 40px;
  overflow: hidden;
}

.compass .disc {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 200;
  background-image: url("../assets/compass.png");

  width: 260px;
  height: 260px;
  background-size: 100%;
}

.compass .arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background-image: url("../assets/compass-arrow.svg");

  width: 260px;
  height: 260px;
  background-size: 100%;
}
</style>

<script>
export default {
  name: "Qibla",
  mounted() {
    setTimeout(() => {
      if (window.DeviceOrientationEvent) {
        document.getElementById("notice").innerHTML = "";
        window.addEventListener(
          "deviceorientation",
          (eventData) => {
            // gamma: Tilting the device from left to right. Tilting the device to the right will result in a positive value.
            const tiltLR = eventData.gamma;
            // beta: Tilting the device from the front to the back. Tilting the device to the front will result in a positive value.
            const tiltFB = eventData.beta;
            // alpha: The direction the compass of the device aims to in degrees.
            const dir = eventData.alpha;
            // Call the function to use the data on the page.
            deviceOrientationHandler(tiltLR, tiltFB, dir);
          },
          false
        );
      } else {
        document.getElementById("notice").innerHTML = "Fehler";
      }

      function deviceOrientationHandler(tiltLR, tiltFB, dir) {
        document.getElementById("tiltLR").innerHTML = Math.ceil(tiltLR);
        document.getElementById("tiltFB").innerHTML = Math.ceil(tiltFB);
        document.getElementById("direction").innerHTML = Math.ceil(dir);
        // Rotate the disc of the compass. - CSS transform
        const compassDisc = document.getElementById("compassDiscImg");
        compassDisc.style.transform = `rotate(${dir}deg)`;
        compassDisc.style.webkitTransform = `rotate(${dir}deg)`;
        compassDisc.style.MozTransform = `rotate(${dir}deg)`;
      }
    }, 500);
  },
};
</script>
